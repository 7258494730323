@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'SF Pro';
    src: url('../assets/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AirbnbCereal_W_Bd';
    src: url('../assets/AirbnbCereal_W_Bd.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'New York';
    src: url('../assets/NewYorkMedium-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
