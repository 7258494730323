@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
@font-face {
    font-family: 'SF Pro';
    src: url(/static/media/SFProDisplay-Regular.d09549c1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AirbnbCereal_W_Bd';
    src: url(/static/media/AirbnbCereal_W_Bd.fab1fd4c.otf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'New York';
    src: url(/static/media/NewYorkMedium-Semibold.36ef813e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

